var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import UserCaseAllot from "~/components/case-info/user-case-allot.vue";
import UserCaseBatchAllot from "~/components/case-info/user-case-batch-allot.vue";
import { State } from "vuex-class";
import { Layout } from "~/core/decorator";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { DistributeCaseService } from "~/services/domain-service/distribute-case.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { MENU_CHANNEL } from "~/config/enum.business";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import DelCaseBatchNumberForever from "~/components/case-manage/case-adjustment/del-case-batch-number-forever.vue";
import AppendWorkOrder from "~/components/case-manage/case-adjustment/append-work-order.vue";
import FollowAllQueryBatch from "~/components/case-manage/case-adjustment/follow-all-query-batch.vue";
import UpdateBaseCase from "~/components/case-manage/case-all-manage/update-base-case.vue";
var WaitDistributeCase = /** @class */ (function (_super) {
    __extends(WaitDistributeCase, _super);
    function WaitDistributeCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 地区已下发
        _this.caseIssuedFlag = "AREA_HAS_ISSUED";
        _this.channel = MENU_CHANNEL.DISTRIBUTE_PERSONAL;
        _this.selectionList = [];
        _this.batchNumberList = [];
        _this.currentCase = {};
        _this.operateId = "";
        _this.currentDate = {};
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            principalId: "",
            casePool: _this.caseIssuedFlag,
            batchNumber: "",
            departCode: "",
            phone: "",
            personalName: "",
            certificateNo: "",
            city: "",
            handsNumber: "",
            delegationDate: [],
            endCaseDate: [],
            cardNo: "",
            account: "",
            address: "",
            employerName: "",
            delegationMonth: "",
            cardNoType: "",
            unhealthyFlag: "",
            receiveName: "",
            batchNo: "",
            extensionInfo: "",
            extensionAgent: "",
            retireCaseDays: "",
            leftAmt: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            } //逾期天数
        };
        _this.dataSet = [];
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
            // cardNo: [{ min: 6, message: '请输入至少6位的卡号', trigger: 'blur' }],
            account: [{ min: 6, message: '请输入至少6位的账号', trigger: 'blur' }]
        };
        _this.dialog = {
            personalManualDivision: false,
            batchDistribution: false,
            batchNumberDelForever: false,
            workerOrder: false,
            detail: false,
            updateCase: false
        };
        return _this;
    }
    WaitDistributeCase.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 刷新数据列
     */
    WaitDistributeCase.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.distributeCaseService
            .searchDetalPersonalDisCase(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) { return (_this.dataSet = data); });
    };
    /**
     * 搜索条件重置
     */
    WaitDistributeCase.prototype.reset = function () {
        var form = this.$refs["form"];
        if (form) {
            form.resetForm();
        }
    };
    WaitDistributeCase.prototype.onManualSeparate = function () {
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要分配的案件");
            return;
        }
        this.dialog.personalManualDivision = true;
    };
    WaitDistributeCase.prototype.onPersonalManualDivisionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["user-case-allot"];
            form.refreshData();
        });
    };
    /**
     * 批次分配
     */
    WaitDistributeCase.prototype.onBatchDistribution = function () {
        this.dialog.batchDistribution = true;
    };
    WaitDistributeCase.prototype.onBatchDistributionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["user-case-batch-allot"];
            form.refreshData();
        });
    };
    /**
     * 案件永久删除
     */
    WaitDistributeCase.prototype.delCaseForever = function () {
        var _this = this;
        if (this.selectionList.length) {
            var idsForever_1 = this.selectionList.map(function (v) { return v.id; });
            this.$confirm("确定删除已选择的案件吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                _this.loading.state = true;
                _this.collectionCaseService.deleteBaseCaseForever({ idsForever: idsForever_1 }, _this.loading).subscribe(function (data) {
                    _this.$message.success('操作成功！');
                    _this.refreshData();
                }, function (_a) {
                    var msg = _a.msg;
                });
            })
                .catch(function () { });
        }
        else {
            this.$message("请选择要删除的案件！");
        }
    };
    /**
     * 添加工单
     */
    WaitDistributeCase.prototype.appendWorkorder = function (scope) {
        this.currentDate = scope.row;
        this.dialog.workerOrder = true;
    };
    __decorate([
        Dependencies(PageService)
    ], WaitDistributeCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], WaitDistributeCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], WaitDistributeCase.prototype, "distributeCaseService", void 0);
    __decorate([
        Dependencies(CollectionCaseService)
    ], WaitDistributeCase.prototype, "collectionCaseService", void 0);
    __decorate([
        State
    ], WaitDistributeCase.prototype, "branchList", void 0);
    __decorate([
        State
    ], WaitDistributeCase.prototype, "principalList", void 0);
    WaitDistributeCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                NumberRange: NumberRange,
                UserCaseAllot: UserCaseAllot,
                UserCaseBatchAllot: UserCaseBatchAllot,
                PrincipalBatch: PrincipalBatch,
                DelCaseBatchNumberForever: DelCaseBatchNumberForever,
                AppendWorkOrder: AppendWorkOrder,
                FollowAllQueryBatch: FollowAllQueryBatch,
                UpdateBaseCase: UpdateBaseCase
            }
        })
    ], WaitDistributeCase);
    return WaitDistributeCase;
}(Vue));
export default WaitDistributeCase;
